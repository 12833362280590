import clsxm from 'sly/lib/clsxm';
import { Link } from 'sly/system';
import Heading from 'sly/system/Heading';

function City({
  cityString,
  tab,
  hideCount = false,
}: {
  cityString: string;
  tab: string;
  hideCount?: boolean;
}) {
  const tabToLinkDict = {
    'Assisted Living': '/assisted-living',
    'Independent Living': '/independent-living',
    'Memory Care': '/memory-care',
  };
  const cityStringArray = cityString.split(',');
  const link = cityStringArray[0];
  const cityName = cityStringArray
    .slice(1, hideCount ? cityStringArray.length : cityStringArray.length - 1)
    .join(',');
  const communityCount = cityStringArray[cityStringArray.length - 1];
  const community = `${communityCount} ${
    communityCount === '1' ? 'community' : 'communities'
  }`;
  const processedLink = `${
    tabToLinkDict[tab as keyof typeof tabToLinkDict]
  }/${link.replace(' ', '-')}`;
  return (
    <div className='mb-4'>
      <Link to={processedLink}>
        <Heading font='font-t-xs-azo'>{cityName.split('-').join(' ')}</Heading>
      </Link>
      {!hideCount && (
        <span className='font-b-s text-slate-lighter-40'>{community}</span>
      )}
    </div>
  );
}

export default function ExploreByCitySection({
  popularCitiesForSection,
  active,
  tab,
  hideCount = false,
}: {
  popularCitiesForSection: string;
  active: boolean;
  tab: string;
  hideCount?: boolean;
}) {
  return (
    <div
      className={clsxm(
        active ? '' : 'hidden',
        'mx-auto mt-6 w-full columns-2 sm:columns-3 md:columns-4'
      )}
    >
      {popularCitiesForSection.split('\n').map((city) => (
        <City cityString={city} key={city} tab={tab} hideCount={hideCount} />
      ))}
    </div>
  );
}
